var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "about-page" },
    [
      _c(
        "about-block",
        {
          attrs: {
            title: "О проекте",
            "left-image": require("../assets/images/about/left-image.png"),
            "float-image": {
              source: require("@/assets/images/about/float.jpg"),
              mobile: require("@/assets/images/about/float-m.jpg"),
            },
          },
        },
        [
          _c("p", { attrs: { slot: "text" }, slot: "text" }, [
            _vm._v(
              " Расположенный на пересечении двух крупнейших трасс Москвы - Рублевского шоссе и Кутузовского проспекта - ЖК Mainstreet создан для тех, кто ценит свободу перемещений, комфортное жилье и возможность жить гармоничной жизнью. "
            ),
          ]),
          _c("p", { attrs: { slot: "text" }, slot: "text" }, [
            _vm._v(
              " Непосредственная близость к деловым центрам, паркам и wellness-центрам делает Mainstreet идеальным местом для качественной жизни и развития всех сфер деятельности. "
            ),
          ]),
          _c("span", { attrs: { slot: "quote" }, slot: "quote" }, [
            _vm._v(
              " Mainstreet — продолжение нового Арбата, исторический путь в Европу. "
            ),
          ]),
        ]
      ),
      _c(
        "why-block",
        {
          attrs: {
            title: "Почему ЖК Mainstreet",
            items: _vm.items,
            image: {
              source: require("@/assets/images/about/why.jpg"),
              tablet: require("@/assets/images/about/why-t.jpg"),
              mobile: require("@/assets/images/about/why-m.jpg"),
            },
          },
        },
        [
          _c("p", { attrs: { slot: "text" }, slot: "text" }, [
            _vm._v(
              " Насладитесь красотой современных архитектурных решений. Изучите продуманость каждого уголка комплекса - от подземного паркинга до закрытого двора с развитой инфраструктурой. "
            ),
          ]),
          _c("p", { attrs: { slot: "text" }, slot: "text" }, [
            _vm._v(
              " Оцените пешую и транспортную доступность до деловых и рекреационных зон. Это ваш новый дом - стильный и комфортный. "
            ),
          ]),
        ]
      ),
      _c("image-gallery-block", {
        attrs: {
          "initial-tab": "Архитектура",
          "gallery-arrow-left": "about__gallery__slider-prev",
          "gallery-arrow-right": "about__gallery__slider-next",
        },
      }),
      _c(
        "location-block",
        { attrs: { title: "Местоположение", "initial-tab": "Удобство" } },
        [
          _c("template", { slot: "description" }, [
            _c("div", {}, [
              _vm._v(
                " ЖК Mainstreet - это идеальное место для тех, кто ценит мобильность, эстетику и возможность комфортного отдыха. Оцените сами превосходную доступность до всех важных объектов инфраструктуры. "
              ),
            ]),
          ]),
        ],
        2
      ),
      _c("slider-news", { attrs: { title: "Новости", navigation: true } }),
      _c("callback-block", {
        attrs: { id: "callback", "id-formblock": "question-block__callback" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }