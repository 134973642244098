<template>
  <div class="why-block">
    <content-wrapper-block>
      <page-title class="why-block__title" white>
        {{ title }}
      </page-title>

      <div class="why-block__container">
        <div class="why-block__content content-block">
          <div class="content-block__text">
            <slot name="text" />
          </div>
          <div class="content-block__text">
            <time-block
              class="why-block__time"
              :items="items"
              dismiss-after-elem
            />
          </div>
        </div>
        <div class="why-block__content image-block">
          <picture>
            <source :srcset="image.source" media="(min-width: 992px)" />
            <source :srcset="image.tablet" media="(min-width: 768px)" />
            <img class="image-block__content" :src="image.mobile" />
          </picture>
        </div>
      </div>
    </content-wrapper-block>
  </div>
</template>

<script>
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import TimeBlock from "@/components/elements/TimeBlock";
import PageTitle from "@/components/elements/PageTitle";

export default {
  name: "WhyBlock",
  components: {
    PageTitle,
    ContentWrapperBlock,
    TimeBlock,
  },
  props: {
    title: String,
    image: {
      type: Object,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.content-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.why-block {
  background: #231f20;
  overflow: hidden;

  @media screen and (min-width: $screen-md) {
    padding: 55px 0 70px 0;
  }

  .content-wrapper {
    overflow: visible;
  }

  &__title {
    font-family: "Bebas Neue", sans-serif;
    font-size: 48px;
    line-height: 130%;
    letter-spacing: 0.05em;
    color: #ffffff;
  }
  &__container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    height: auto;

    @media screen and (min-width: $screen-md) {
      flex-direction: row;
      height: 502px;
    }
  }
  &__content {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 170%;
    letter-spacing: 0.03em;
    color: rgba(255, 255, 255, 0.9);
    width: 100%;
    margin-right: 66px;

    @media screen and (min-width: $screen-sm) {
      font-size: 16px;
    }

    &.image-block {
      overflow: hidden;
      position: relative;
      margin: 0 -15px -15px -15px;
      width: calc(100% + 30px);
      height: 276px;

      @media screen and (min-width: $screen-md) {
        overflow: visible;
        margin: 0;
        position: relative;
        right: 0;
        width: calc(50% - 33px);
      }
    }

    @media screen and (min-width: $screen-md) {
      width: calc(50% - 33px);
    }
  }
  &__time {
    margin-bottom: 0;

    .time-block__item.dismiss-after {
      margin-right: 115px;
    }

    @media screen and (min-width: $screen-md) {
      margin-bottom: 94px;
    }
  }
}
.image-block {
  &__content {
    height: 100%;

    @media screen and (min-width: $screen-mini) {
      height: auto;
    }
  }
}
</style>

<style lang="scss">
.why-block {
  &__time {
    .time-block__item {
      line-height: 53px;

      &.dismiss-after {
        margin-right: 15px;
        height: 75px;
        align-items: flex-start;

        @media screen and (min-width: $screen-xs) {
          margin-right: 29px;
          height: auto;
          align-items: stretch;
        }
        @media screen and (min-width: $screen-mini) {
          margin-right: 60px;
        }
        @media screen and (min-width: $screen-md) {
          margin-right: 115px;
        }
      }
    }
    .time-item__text {
      font-size: 10px;
    }
    .time-item__header_big {
      font-size: 48px !important;
      font-weight: 400;

      @media screen and (min-width: $screen-xs) {
        font-size: 48px !important;
      }
      @media screen and (min-width: $screen-mini) {
        font-size: 80px !important;
      }
    }
    .time-block__content {
      position: absolute;
      bottom: 0;
      margin-right: 0;

      &:before {
        height: 75px;
        bottom: 3px;
      }

      @media screen and (min-width: $screen-mini) {
        &:before {
          height: 78px;
          bottom: 13px;
        }
      }
      @media screen and (min-width: $screen-md) {
        bottom: auto;
        margin-right: 50px;
      }
    }
  }
}
</style>
