<template>
  <div :class="['quote', `quote-${theme}`]">
    <div class="quote__symbol">
      <icon-quote class="quote__symbol--icon" />
    </div>
    <div class="quote__text">
      <slot />
    </div>
  </div>
</template>

<script>
import IconQuote from "@/components/icons/IconQuote";

export default {
  name: "Quote",
  components: {
    IconQuote,
  },
  props: {
    theme: {
      type: String,
      default: "dark",
    },
  },
};
</script>

<style scoped lang="scss">
.quote {
  max-width: 750px;
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.2);
  justify-content: space-around;
  margin-top: 67px;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;

  @media screen and (min-width: $screen-sm) {
    flex-direction: row;
    align-items: center;
    padding: 16px 51px;
  }

  &__symbol {
    font-family: $font-family-bebas;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.05em;
    color: rgba(255, 255, 255, 0.2);
    display: flex;
    height: 50px;
    width: 50px;
    align-items: flex-start;

    &--icon {
      width: 35px;
      height: 35px;
    }

    @media screen and (min-width: $screen-sm) {
      height: 100px;
      width: 100px;
      align-items: center;

      &--icon {
        width: 60px;
        height: 60px;
      }
    }
  }
  &__text {
    font-family: $font-family-bebas;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 130%;
    letter-spacing: 0.05em;
    color: #ffffff;
    margin-left: 0;

    @media screen and (min-width: $screen-sm) {
      margin-left: 13px;
      font-size: 30px;
    }
  }

  &-dark {
    background: #231f20;
    border: 1px solid rgba(255, 255, 255, 0.2);

    .quote__symbol {
      color: rgba(255, 255, 255, 0.2);
    }
    .quote__text {
      color: #ffffff;
    }
  }

  &-white {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);

    .quote__symbol {
      color: rgba(24, 24, 27, 0.2);
    }
    .quote__text {
      color: #18181b;
    }
  }
}
</style>
