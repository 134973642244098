<template>
  <div class="about-page">
    <about-block
      title="О проекте"
      :left-image="require('../assets/images/about/left-image.png')"
      :float-image="{
        source: require('@/assets/images/about/float.jpg'),
        mobile: require('@/assets/images/about/float-m.jpg'),
      }"
    >
      <p slot="text">
        Расположенный на пересечении двух крупнейших трасс Москвы - Рублевского
        шоссе и Кутузовского проспекта - ЖК Mainstreet создан для тех, кто ценит
        свободу перемещений, комфортное жилье и возможность жить гармоничной
        жизнью.
      </p>
      <p slot="text">
        Непосредственная близость к деловым центрам, паркам и wellness-центрам
        делает Mainstreet идеальным местом для качественной жизни и развития
        всех сфер деятельности.
      </p>
      <span slot="quote">
        Mainstreet — продолжение нового Арбата, исторический путь в Европу.
      </span>
    </about-block>

    <!-- <features-block /> -->

    <why-block
      title="Почему ЖК Mainstreet"
      :items="items"
      :image="{
        source: require('@/assets/images/about/why.jpg'),
        tablet: require('@/assets/images/about/why-t.jpg'),
        mobile: require('@/assets/images/about/why-m.jpg'),
      }"
    >
      <p slot="text">
        Насладитесь красотой современных архитектурных решений. Изучите
        продуманость каждого уголка комплекса - от подземного паркинга до
        закрытого двора с развитой инфраструктурой.
      </p>
      <p slot="text">
        Оцените пешую и транспортную доступность до деловых и рекреационных зон.
        Это ваш новый дом - стильный и комфортный.
      </p>
    </why-block>

    <image-gallery-block
      initial-tab="Архитектура"
      gallery-arrow-left="about__gallery__slider-prev"
      gallery-arrow-right="about__gallery__slider-next"
    />

    <location-block title="Местоположение" initial-tab="Удобство">
      <template slot="description">
        <div class="">
          ЖК Mainstreet - это идеальное место для тех, кто ценит мобильность,
          эстетику и возможность комфортного отдыха. Оцените сами превосходную
          доступность до всех важных объектов инфраструктуры.
        </div>
      </template>
    </location-block>
    <slider-news title="Новости" :navigation="true" />
    <callback-block id="callback" id-formblock="question-block__callback" />
  </div>
</template>

<script>
import WhyBlock from "@/components/blocks/WhyBlock";
import AboutBlock from "@/components/blocks/AboutBlock";
import SliderNews from "@/components/blocks/SliderNews";
import CallbackBlock from "@/components/blocks/CallbackBlock";
import FeaturesBlock from "@/components/blocks/FeaturesBlock";
import LocationBlock from "@/components/blocks/LocationBlock";
import ImageGalleryBlock from "@/components/blocks/ImageGalleryBlock";

export default {
  name: "AboutPage",
  components: {
    WhyBlock,
    AboutBlock,
    SliderNews,
    CallbackBlock,
    FeaturesBlock,
    LocationBlock,
    ImageGalleryBlock,
  },
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Новый ЖК на пересечении Кутузовского проспекта и Рублевского шоссе. 5 минут от метро и МЦД. Свой паркинг и благоустроенный двор.",
      },
    ],
  },
  data() {
    return {
      items: [
        {
          big: "562",
          text: "апартамента",
        },
        {
          big: "233",
          text: "машино-места",
        },
        {
          big: "40+",
          text: "планировок",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
