var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "about-block" },
    [
      _c(
        "content-wrapper-block",
        { staticClass: "about-block__wrapper" },
        [
          _c("bread-crumbs"),
          _c("page-title", { attrs: { white: "" } }, [
            _vm._v(" " + _vm._s(_vm.title) + " "),
          ]),
          _c("div", { staticClass: "about-block__container" }, [
            _c("div", { staticClass: "about-block__content" }, [
              _c("div", { staticClass: "about-block__left" }, [
                _c("img", {
                  staticClass: "about-block__image",
                  attrs: { src: _vm.leftImage, alt: "" },
                }),
              ]),
              _c(
                "div",
                { staticClass: "about-block__text" },
                [_vm._t("text")],
                2
              ),
            ]),
            _c(
              "div",
              { staticClass: "about-block__content about-float-block" },
              [
                _c("picture", [
                  _c("source", {
                    attrs: {
                      srcset: _vm.floatImage.source,
                      media: "(min-width: 768px)",
                    },
                  }),
                  _c("img", {
                    staticClass: "about-float-block__image",
                    attrs: { src: _vm.floatImage.mobile },
                  }),
                ]),
              ]
            ),
          ]),
          _c("quote", [_vm._t("quote")], 2),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }