<template>
  <div class="about-block">
    <content-wrapper-block class="about-block__wrapper">
      <bread-crumbs />
      <page-title white>
        {{ title }}
      </page-title>
      <div class="about-block__container">
        <div class="about-block__content">
          <div class="about-block__left">
            <img class="about-block__image" :src="leftImage" alt="" />
          </div>
          <div class="about-block__text">
            <slot name="text" />
          </div>
        </div>
        <div class="about-block__content about-float-block">
          <picture>
            <source :srcset="floatImage.source" media="(min-width: 768px)" />
            <img class="about-float-block__image" :src="floatImage.mobile" />
          </picture>
        </div>
      </div>
      <quote>
        <slot name="quote" />
      </quote>
    </content-wrapper-block>
  </div>
</template>

<script>
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import PageTitle from "@/components/elements/PageTitle";
import Quote from "@/components/elements/Quote";
import BreadCrumbs from "@/components/elements/BreadCrumbs";

export default {
  name: "AboutBlock",
  components: {
    BreadCrumbs,
    ContentWrapperBlock,
    PageTitle,
    Quote,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    leftImage: {
      type: String,
      default: "",
    },
    floatImage: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.about-block {
  background: #231f20;

  &__container {
    position: relative;
  }
  &__image {
    width: 100%;

    @media screen and (min-width: $screen-md) {
      width: auto;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    margin-top: 0;

    @media screen and (min-width: $screen-md) {
      flex-direction: row;
      margin-top: 31px;
    }
  }
  &__left {
    margin-right: 30px;

    @media screen and (min-width: $screen-lg) {
      margin-right: 90px;
    }
  }
  &__text {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 170%;
    letter-spacing: 0.03em;
    color: rgba(255, 255, 255, 0.9);

    @media screen and (min-width: $screen-sm) {
      font-size: 18px;
    }
  }
  &__wrapper {
    padding-bottom: 70px;
  }
}
.about-float-block {
  margin-top: -35px;
  margin-left: 25px;

  @media screen and (min-width: $screen-md) {
    margin-top: -77px;
    margin-left: 60px;
  }

  &__image {
    width: 100%;

    @media screen and (min-width: $screen-md) {
      width: auto;
    }
  }
}
</style>
