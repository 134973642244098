var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: ["quote", `quote-${_vm.theme}`] }, [
    _c(
      "div",
      { staticClass: "quote__symbol" },
      [_c("icon-quote", { staticClass: "quote__symbol--icon" })],
      1
    ),
    _c("div", { staticClass: "quote__text" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }