var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "why-block" },
    [
      _c(
        "content-wrapper-block",
        [
          _c(
            "page-title",
            { staticClass: "why-block__title", attrs: { white: "" } },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          ),
          _c("div", { staticClass: "why-block__container" }, [
            _c("div", { staticClass: "why-block__content content-block" }, [
              _c(
                "div",
                { staticClass: "content-block__text" },
                [_vm._t("text")],
                2
              ),
              _c(
                "div",
                { staticClass: "content-block__text" },
                [
                  _c("time-block", {
                    staticClass: "why-block__time",
                    attrs: { items: _vm.items, "dismiss-after-elem": "" },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "why-block__content image-block" }, [
              _c("picture", [
                _c("source", {
                  attrs: {
                    srcset: _vm.image.source,
                    media: "(min-width: 992px)",
                  },
                }),
                _c("source", {
                  attrs: {
                    srcset: _vm.image.tablet,
                    media: "(min-width: 768px)",
                  },
                }),
                _c("img", {
                  staticClass: "image-block__content",
                  attrs: { src: _vm.image.mobile },
                }),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }